import "./App.css";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { AiFillMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
function Footing() {
  const handlefacebook = () => {
    // URL to the app file
    const appFileUrl =
      "https://www.facebook.com/profile.php?id=61560653348052&mibextid=LQQJ4d";
    const link = document.createElement("a");
    link.href = appFileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleInstagram = () => {
    // URL to the app file
    const appFileUrl =
      "https://www.instagram.com/eatprotein_offl?igsh=cjJtZnVtdjlzcGpq";
    const link = document.createElement("a");
    link.href = appFileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="container-fluid footer" style={{ marginTop: 20 }}>
        <div className="container">
          <div className="row" style={{ textAlign: "center" }}>
            <div className="d-none d-md-block col-md-3 col-sm-3 p-2">
              <div>
                <Link to="/">
                  {" "}
                  <img
                    src={"/EAT_PROTEIN_TRANSPARENT.png"}
                    style={{ width: 235, height: 95 }}
                    href="#home"
                    className="footer-img"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* <div className="d-n d-md-block col-md-3 col-sm-3 p-2">
              <div>
                <Link to="/">
                  {" "}
                  <img
                    src={"/eat logo.png"}
                    style={{ width: 235, height: 95 }}
                    href="#home"
                    className="footer-img"
                    alt=""
                  />
                </Link>
              </div>
            </div> */}
            <div className="col-xs-12 col-md-3 col-sm-3 p-2">
              <div
                id="footer"
                className=""
                style={{ textAlign: "center", fontSize: 18 }}
              >
                Contact Us
                <br />
                <BsFillTelephoneFill /> +91 9440701380
                <br />
                <AiFillMail /> support@eatprotein.in
              </div>
            </div>
            <div className="col-xs-12 col-md-3 col-sm-3 p-2">
              Follow Us
              <br />
              <FaInstagram
                onClick={handleInstagram}
                size={27}
                style={{ marginRight: 0, marginTop: 10 }}
              />
               <FaYoutube
              onClick={handlefacebook}
              size={27}
              style={{ marginTop: 10 }}
              />
              <CiFacebook
                onClick={handlefacebook}
                size={27}
                style={{ marginTop: 10 }}
              />
             

            </div>
            <div className="col-xs-12 col-md-3 col-sm-3 p-2">
              <Link to="/terms" className="home-nav footer-color-changer">
                Terms & Conditions <br />
              </Link>
              <Link to="/privacy" className="home-nav footer-color-changer">
                Privacy Policy <br />
              </Link>
              <Link to="/refund" className="home-nav footer-color-changer">
                {" "}
                Refund Policy <br />
              </Link>
              <Link to="/shipping" className="home-nav footer-color-changer">
                Shipping Policy <br />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* <div className="col-12">
            <Navbar expand="lg">
                <Navbar.Brand className='w-50'>
                    <div className='d-flex flex-row'  style={{ width: "100%" }}>
                        <div className='col-12' style={{width:"29%"}}>
                        <Link to="/" style={{ width: "100%" }}> <img src={"/eat logo.png"} href="#home" className="footer-img" alt="" /></Link>
                        </div>
                        <div id="footer" className='w-50' style={{ textAlign: "center",fontSize:18}}>

                            Contact Us
                            <br />
                            <BsFillTelephoneFill/> +91 7036356760
                            <br />
                            <AiFillMail/> support@eatprotein.in

                        </div>
                        <div className=''  style={{ textAlign: "center",fontSize:18 }}>

                            Follow Us
                            <br />
                            <FaInstagram onClick={handleInstagram} size={27} style={{marginRight:20,marginTop:20}}/>
                            
                            <CiFacebook onClick={handlefacebook} size={27} style={{marginTop:20}}/>

                        </div>
                    </div>

                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav " className=' w-50'/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="margin-nav set-width">

                        <Nav.Link><Link to="/terms" className="home-nav footer-color-changer"> <br />Terms & Conditions <br /></Link></Nav.Link>
                        <Nav.Link ><Link to="/privacy" className="home-nav footer-color-changer"> <br />Privacy Policy <br /></Link></Nav.Link>
                        <Nav.Link><Link to="/refund" className="home-nav footer-color-changer"> <br />Refund Policy <br /></Link></Nav.Link>
                        <Nav.Link><Link to="/shipping" className="home-nav footer-color-changer"> <br />Shipping Policy <br /></Link></Nav.Link>
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        </div> */
}

export default Footing;
